import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import { useDevice } from '@/helpers/deviceContext';
import { usePlatform } from '@/helpers/platformContext';

import { useToplineReducer } from './toplineContext';

const Marquee = dynamic(() => import('react-fast-marquee'));

const ToplineLive: React.FC = () => {
  const [{ liveVisible }, dispatch] = useToplineReducer();
  const [isMoving, setIsMoving] = useState(false);
  const liveTitleEl = useRef<HTMLDivElement>(null);
  const { live_config } = usePlatform();
  const router = useRouter();
  const platform = usePlatform();
  const { isMobile } = useDevice();

  useEffect(() => {
    const isGeneralVisible =
      live_config &&
      router.route !== '/' &&
      router.query.slug !== live_config.slug;
    if (isGeneralVisible) {
      const { live_topline_mobile, live_topline_desktop } =
        platform.settings_custom;
      const isVisible = isMobile
        ? (live_topline_mobile ?? true)
        : (live_topline_desktop ?? false);
      dispatch({ type: 'SET_LIVE_VISIBLE', payload: isVisible });
    }
  }, [router, live_config, dispatch]);

  useEffect(() => {
    const interval = setInterval(checkTitleSize, 5000);

    function checkTitleSize() {
      const childEl = liveTitleEl.current;
      const parentEl = childEl?.parentElement;
      if (!childEl || !parentEl) return;
      const isMoving = childEl.clientWidth >= parentEl.clientWidth;
      setIsMoving(isMoving);
      if (isMoving) clearInterval(interval);
    }
  }, []);

  return liveVisible && live_config ? (
    <Link href={live_config.path}>
      <a className="text-white grid hover:text-white bg-active h-[34px] grid-cols-[60px_1fr]">
        <span className="flex items-center py-2 border-r border-opacity-30">
          <svg
            className="mr-1 ml-2"
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4.5" cy="4.5" r="4" fill="white" />
          </svg>
          <span>Live</span>
        </span>
        <div className="py-2 overflow-hidden">
          {isMoving ? (
            <Marquee gradient={false}>
              <span className="mr-2">{live_config.title}</span>
            </Marquee>
          ) : (
            <div
              className="ml-4 inline-block whitespace-nowrap"
              ref={liveTitleEl}
            >
              {live_config.title}
            </div>
          )}
        </div>
      </a>
    </Link>
  ) : null;
};

export default ToplineLive;
